import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

const mediaQuery = graphql`
  query getMediaQuery {
    allContentfulMedia {
      nodes {
        title
        category
        description {
            json
        }
        thumbnail {
          file {
            url
          }
          title
        }
      }
    }
  }
`

class MediaPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  exists (value) { return value != null && typeof value !== "undefined"}

  getCategories(nodes) {
    const uniqueCategories = []
    nodes.map(entry => {
      if (uniqueCategories.indexOf(entry.category) === -1) {
        uniqueCategories.push(entry.category)
      }
    })

    return uniqueCategories
  }

  render() {
    const options = {
      renderNode: {
        [INLINES.HYPERLINK]: (node) => {
          if((node.data.uri).includes("youtube.com/embed")) {
            return <iframe style={{ width: "100%", height: "400px"}} src="https://www.youtube.com/embed/TtCgET_vORs" frameBorder="0"
                           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                           allowFullScreen/>
          }
        },
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <span>
            {children}
            <br />
          </span>
        )
      }
    }

    return (
      <StaticQuery
        query={mediaQuery}
        render={data => {
          return (
            <Layout>
              <SEO
                title="Media"
                keywords={[`Media`, `Böcker`, `Video`, `Blogg`]}
              />

              {this.getCategories(data.allContentfulMedia.nodes).map(category => {
                return (
                  <React.Fragment key={category}>
                    <div className={"row"} key={category} style={{ marginBottom: "25px" }}>
                      <div className={"twelve columns"}>
                        <h5 style={{ fontWeight: "900" }}>{category}</h5>
                      </div>
                    </div>
                    {
                      data.allContentfulMedia.nodes.map(entry => {
                        return (
                          entry.category === category ? (
                            <React.Fragment key={entry.title}>
                              <div className={"row"} style={{ marginBottom: "25px" }}>
                                {
                                  this.exists(entry.thumbnail) ? (
                                    <>
                                      <div className={"four columns"}>
                                        <img
                                          style={{ width: "100%" }}
                                          src={entry.thumbnail.file.url}
                                          alt={entry.thumbnail.title}
                                        />
                                      </div>
                                      <div className={"eight columns"}>
                                        <h5 style={{ fontWeight: "900", fontSize: "1.8rem" }}>{entry.title}</h5>
                                        {documentToReactComponents(entry.description.json, options)}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className={"twelve columns"}>
                                        <h5 style={{ fontWeight: "900", fontSize: "1.8rem" }}>{entry.title}</h5>
                                        {documentToReactComponents(entry.description.json, options)}
                                      </div>
                                    </>
                                  )
                                }

                              </div>
                            </React.Fragment>
                          ) : null

                        )

                      })
                    }
                  </React.Fragment>
                )
              })}

            </Layout>
          )
        }}
      />
    )
  }
}

export default MediaPage
